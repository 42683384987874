import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, retry, Subscription, tap } from 'rxjs';

import { environment } from '@environments/environment';
import {
    ResponseActivationSms,
    ResponseAutoCompletePostcode,
    ResponseCompleteAccount,
    ResponseForwardValidateEmail,
    ResponseRefreshToken,
    ResponseSentPhoto,
    ResponseSingInComplete,
    ResponseSingInSimple,
    ResponseSingUp,
    ResponseSingUpGoogle,
} from '@app/models/authentication/response-authentication.model';
import { DtoSingIn } from '@app/models/authentication/dto-sing-In.model';
import { LoginObservable } from '@app/observables/login.observable';
import { DtoSingUp } from '@app/models/authentication/dto-sing-up.model';
import { DtoRecoveryPassword } from '@app/models/authentication/dto-recovery-password.model';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    listSubscription: Subscription[];

    private http = inject(HttpClient);
    private readonly API_URI_V1 = environment.PROXY
        ? '/api/v1/'
        : environment.API_URL_V1;

    private readonly API_URI_V2 = environment.PROXY
        ? '/api/v2/'
        : environment.API_URL_V2;

    private readonly loginObservable = inject(LoginObservable);
    private readonly localStorageService = inject(LocalStorageService);

    singIn(
        dto: DtoSingIn
    ): Observable<ResponseSingInComplete | ResponseSingInSimple> {
        return this.http.post<ResponseSingInComplete | ResponseSingInSimple>(
            `${this.API_URI_V1}auth/sing-in`,
            dto
        );
        // .pipe(retry(2));
    }

    logout(): Observable<void> {
        return this.http.post<void>(`${this.API_URI_V1}auth/logout`, {});
    }

    autoCompletePostcode(
        dto: string
    ): Observable<ResponseAutoCompletePostcode> {
        let params = new HttpParams();
        params = params.append('postalCode', dto);

        return this.http.get<ResponseAutoCompletePostcode>(
            `${this.API_URI_V1}postcode/autocomplete`,
            { params }
        );
    }

    singUp(dto: DtoSingUp): Observable<ResponseSingUp> {
        return this.http.post<ResponseSingUp>(
            `${this.API_URI_V1}auth/sing-up`,
            dto
        );
        // .pipe(retry(2));
    }

    singUpGoogle(token: string): Observable<ResponseSingUpGoogle> {
        return this.http.post<ResponseSingUpGoogle>(
            `${this.API_URI_V1}auth/google`,
            { token }
        );
        // .pipe(retry(2));
    }

    completeAccount(
        data: FormData,
        token: string
    ): Observable<ResponseCompleteAccount> {
        return this.http.post<ResponseCompleteAccount>(
            `${this.API_URI_V1}auth/complete-account`,
            data,
            {
                headers: {
                    'Cache-Control': 'no-cache',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        // .pipe(retry(2));
    }

    validateEmailCode(code: string, token: string): Observable<any> {
        return this.http.post<any>(
            `${this.API_URI_V1}auth/validate-email`,
            { code },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    sentPhoto(data: FormData): Observable<ResponseSentPhoto> {
        return this.http.post<ResponseSentPhoto>(
            `${this.API_URI_V1}user/picture`,
            data,
            {
                headers: { 'Cache-Control': 'no-cache' },
            }
        );
    }

    forgotPassword(data: string): Observable<void> {
        let params = new HttpParams();
        params = params.append('email', data);

        return this.http.get<void>(`${this.API_URI_V1}auth/recovery/password`, {
            params,
        });
    }

    recoveryPassword(dto: DtoRecoveryPassword): Observable<void> {
        return this.http.patch<void>(
            `${this.API_URI_V1}auth/recovery/reset/password`,
            dto
        );
    }

    forwardActivationUser(data: string): Observable<void> {
        let params = new HttpParams();
        params = params.append('token', data);

        return this.http.get<void>(
            `${this.API_URI_V1}auth/forward-activation-user`,
            {
                params,
            }
        );
    }

    activationSms(data: string): Observable<ResponseActivationSms> {
        let params = new HttpParams();
        params = params.append('code', data);

        return this.http.get<ResponseActivationSms>(
            `${this.API_URI_V1}activation`,
            {
                params,
            }
        );
    }

    refreshToken(refreshToken: string): Observable<ResponseRefreshToken> {
        return this.http
            .post<ResponseRefreshToken>(
                `${this.API_URI_V1}auth/refresh-tokens`,
                {
                    refreshToken,
                }
            )
            .pipe(
                tap(({ accessToken, refreshToken }) => {
                    this.localStorageService.setItem('token', accessToken);
                    this.localStorageService.setItem(
                        'refreshToken',
                        refreshToken
                    );
                })
            );
    }

    forwardValidateEmail(
        token: string
    ): Observable<ResponseForwardValidateEmail> {
        return this.http.get<ResponseForwardValidateEmail>(
            `${this.API_URI_V1}auth/forward-validate-email`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    googleSingIn(): Observable<void> {
        return this.http.get<void>(`${this.API_URI_V2}auth/google`);
    }

    facebookSingIn(): Observable<void> {
        return this.http.get<void>(`${this.API_URI_V2}auth/facebook`);
    }

    isLoggedIn(): Observable<boolean> {
        if (!this.loginObservable.getData()) {
            return of(false);
        }

        return of(true);
    }
}
